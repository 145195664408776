







































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { push } from '@/inc/utils'

import JobHero from '@/components/job/Hero.vue'
import GWysiwyg from '@/components/g/Wysiwyg.vue'
import MagazineShare from '@/components/magazine/Share.vue'

export default defineComponent({
  name: 'JobSingle',
  components: {
    JobHero,
    GWysiwyg,
    MagazineShare,
  },
  setup() {
    const { content } = useGetters(['content'])

    // Push event to datalayer on click
    const onLinkClick = () => {
      const layer = {
        event: 'job_apply_cta_click',
        jobOfferValue: content.value.title,
      }

      push(layer)
    }

    return {
      content,
      onLinkClick,
    }
  },
})
